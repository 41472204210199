$color-border: $color-text;
$color-text: $color-text;
$color-header-hover: $color-text;
$color-header-solid: $color-dark-green;
$color-light: $color-white;
$color-primary: $color-lime;
$color-primary-hover: $color-lime;
$color-text-muted: $color-grey;
$color-status-active: $color-green;
$color-status-inactive: $color-red;
$color-status-pending: #FFD800;

.icon-loader {
	background-image: url("../assets/images/loader.gif");
	background-size: contain;
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	margin-top: 8px;
}

.react-table-filterable {

	.filters-btn {
		font-size: 16px;
		margin: 0;

		.icon {
			font-size: 16px;
			width: 18px;
			height: 18px;
			display: inline-block;
			vertical-align: middle;
		}
	}

	position: relative;

	table.header-container {
		margin: 0;
		table-layout: fixed;
		width: 100%;
		height: 38px;
		tr {
			border: 0;
			td {
				padding: 0;
			};
		}
	}

	.custom-buttons {
		float: right;
		margin-right: 10px;

		div.custom-checkbox {
			display: inline-block;
		}

		.custom-button-holder {
			display: inline-block;
			button {
				height: 36px;
				margin-right: 20px;
				padding-top: 6px;
				font-size: 18px;
				vertical-align: super;

				img {
					width: 28px;
				}
			}
		}
	}

	.filters-btn {
		cursor: pointer;
		margin: 4px 10px;
		.icon {
			margin-right: 10px;
		}
	}

	.table-title {
		text-align: center;
		font-weight: bold;
		font-size: 22px;

		div {
			margin-top: -4px;
		}
	}

	.add-btn {
		width: 30px;
		position: unset;
		.icon {
			cursor: pointer;
			font-size: 30px;
			height: 30px;
		}
	}

	.date-range-dropdown {
		@include custom-dropdown;
	}

	.ReactTable .rt-thead.-filters {
		input, select {
			height: 33px;
			width: 100%;
			font-size: 16px;
		}
	}
}

div.react-datepicker-wrapper {
	.react-datepicker__close-icon::after {
		background-color: $color-text;
	}

	.react-datepicker__close-icon:hover::after {
		background-color: $color-primary;
	}
}

.ReactTable {
	width: 100%;
	border-radius: 10px;

	.rt-table {
		border-radius: 10px;
	}

	.rt-noData {
		z-index: 0 !important;
	}

	.align-right {
		text-align: right !important;
	}

	.align-center {
		text-align: center !important;
	}

	.custom-dropdown-btn {
		height: 33px;
		width: 100%;
		background-color: $color-white;
		border: 0;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 100;
		text-overflow: clip;
		overflow: hidden;
	}

	.custom-dropdown-btn.date-range-empty {
		color: $color-border;
	}

	.rt-thead.-filters {
		height: 44px;

		div.react-datepicker-wrapper {
			display: block;

			input {
				font-size: 15px;
			}

		}
	}

	.rt-tfoot {
		color: $color-white;
		background-color: $color-header-hover;

		.rt-tr {
			height: 26px;

			.rt-td {
				padding: 2px;
				font-size: 16px;
			}
		}

		.align-right {
			width: 100%;
			text-align: right;
			padding-right: 6px;
		}
	}

	.rt-thead {
		background: $color-header-solid;
		color: $color-white;
		font-weight: 700;
		font-size: 24px;

		.rt-th {
			height: 50px;

			.rt-resizer {
				z-index: 1;
			}

			.react-datepicker-popper {
				.react-datepicker {
					display: block;

					.react-datepicker__month-container, .react-datepicker__time-container {
						display: inline-block;
						vertical-align: top;
						float: none;
					}
				}
			}
		}

		&.-header .rt-tr {
			.rt-th {
				padding-top: 20px;
				height: 60px;
				font-size: 18px;
				text-align: left;
			}

			.rt-th.-sort-desc {
				box-shadow: inset 0 -4px 0 0 $color-primary;
			}

			.rt-th.-sort-asc {
				box-shadow: inset 0 4px 0 0 $color-primary;
			}		
		}

		.rt-th.calendar-column {
			overflow: visible;
			z-index: 1;
		}
	}

	.rt-tbody {
		.rt-td {
			font-size: 16px;
			text-align: left;
			height: 38px;

			input,.custom-checkbox {
				height: 24px;
			}

			.custom-checkbox label{
				height: 24px; 
				width: 24px;
			}
		}

		.rt-td.action-column {
			margin: 0;
			padding: 0;
			text-align: right;
			text-overflow: clip;

			.icon:hover {
				opacity: 0.8;
			}
		}

		.rt-td.custom-control {
			padding: 0 !important;
			margin-top: -2px;
			overflow: visible;

			.multi-value-text {
				overflow: hidden;
				text-overflow: ellipsis;
				height: 26px; 
				margin-top: 5px;
			}
		}

		.rt-tr {
			align-items: center;
		}

		.rt-tr:hover {
			opacity: 0.8;
		}
	}

	.pagination-bottom {
		background-color: $color-header-solid;
		height: 26px;
		font-size: 16px;
		color: $color-light;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		padding: 0 5px;

		.total-rows {
			margin-left: 8px;
			position: absolute;
		}

		.custom-message {
			position: absolute;
			display: inline-block;
			right: 8px;
		}

		.table-navigation {
			display: inline-block;
			white-space: nowrap;

			button[disabled], button[disabled]:hover {
				opacity: 0.4;
				color: $color-light;
				background-color: $color-header-solid !important; 
			}

			.visible-pages-wrapper {
				display: inline-block;
				color: $color-light;
				margin-top: -2px;
				vertical-align: top;
			}

			button {
				height: 22px;
				background: transparent;
				padding: 0 8px;
				overflow: hidden;
				border: none;
				margin: 4px 0;
				outline: none;
				color: $color-light;

				.icon {
					margin-top: -6px;
					margin-left: -3px;
					font-size: 24px;
					color: inherit;
				}
			}

			button:hover {
				color: $color-primary-hover;
			}

			.page-button.page-button-active {
				color: $color-primary;
				font-weight: bold;
			}
			
			.sl-page-size {
				border: none;
				background-color: $color-light;
				vertical-align: top;
				margin-top: 4px;
				height: 19px;
    			font-size: 14px;
			}
		}
	}

	.grid-icon {
		width: 22px;
		height: 22px;
		padding-top: 6px;
		display: inline-block;
		margin-right: 5px;
		font-size: 28px;

		&[disabled] {
			color: $color-grey;
		}
	}

	.status-orb {
		width: 16px;
		height: 16px;
		border-radius: 10px;
		margin-top: 3px;
		background-color: $color-white;

		&-active {
			background-color: $color-status-active;
		}

		&-inactive {
			background-color: $color-status-inactive;
		}

		&-filter {
			margin-top: 8px;
			cursor: pointer;
		}
	}	

	.rt-th.calendar-column .input-date {
		input {
			height: 40px;
			width: 100%;
			padding-right: 30px;
			font-size: 18px;
		}

		&>div{
			background-color: $color-header-solid;
			z-index: 1000;
			width: 300px;
			position: relative;
		}

		button {
			margin-left: -28px;
			height: 24px;
			width: 24px;
			background: $color-light;
			border: 1px;
			border: 0;

			.icon {
				font-size: 16px;
				margin-left: -2px;
				margin-top: 2px;
				color: $color-text-muted;
			}
		}

		button:hover {
			background: $color-status-inactive;

			.icon {
				color: $color-white;
			}
		}

	}

	.space-between {
		display: flex;
		justify-content: space-between;
	}
}