:root {
    --mainDarkBG: #323336;
    --skyBlue: #87CEEB;
    --lightSkyBlue: #87CEFA;
}

.managerHeader .btnContainer {
    background-color: var(--mainDarkBG);
    width: fit-content;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(50,51,54,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(50,51,54,1);
    box-shadow: 0px 0px 5px 0px rgba(50,51,54,1);
    width: fit-content;
    max-width: 100%;
    overflow: scroll;
    white-space: nowrap;
    margin-bottom: 1.5rem;
}

.btnContainer .mBtn {
    padding: 5px 15px;
    transition: 0.3s;
}

.btnContainer .mBtn p {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    color: white;
    font-size: 15px;
}

.btnContainer .mBtn i {
    vertical-align: middle;
    font-size: 14px;
    color: white;
}

.btnContainer .mBtn:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.btnContainer .mBtn:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.btnContainer .mBtn.active {
    background-color: var(--lightSkyBlue);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(50,51,54,1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(50,51,54,1);
    box-shadow: 0px 0px 5px 1px rgba(50,51,54,1);
}

.btnContainer .mBtn:hover{
    background-color: var(--lightSkyBlue);
}

.managerContainer .contentContainer {
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
}

.managerContainer .contentContainer .managerInputs input {
    border-bottom: 1px solid var(--mainDarkBG);
}

.managerContainer .contentContainer .pull-left label {
    background-color: var(--mainDarkBG);
}

.managerContainer .contentContainer .documentsEditor .ql-container {
    max-height: 600px;
    height: 600px !important;
}

.managerContainer .contentContainer .documentsEditor .ql-container .ql-editor {
    padding: 80px;
    text-align: justify;
}