$color-text: #49575a;
// $color-lime: #b5f767;
$color-lime: #87CEEB;
$color-light-green: #e2f5f4;
// $color-dark-green: #5b7377;
$color-dark-green: #323336;
$color-main: #00a6c0;
$color-grey: #EEE;
$color-white: #FFF;
$color-red: #fa6969;
// $color-green: #53D409;
$color-green: #87CEFA;

@import "mixins";
@import "responsive";
@import "react-table";

.small {
  font-size: unset !important;
}

.color-red {
  color: $color-red;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container.adminPanel {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.adminFooterLogo {
  text-align: center;
}

button,
button[disabled] {
  border: none;
  background-color: transparent;
}

form button.post-button.btn-progress:not(.mtr-btn)[disabled] {
  background-color: $color-light-green !important;
  color: $color-text;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button[disabled] {
  background-color: $color-grey !important;
  cursor: default;
}

button[disabled]:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.mtr-btn.btn-progress.btn-red {
  background-color: $color-red;
}

.form-group {
  input.has-button {
    display: inline-block;
    width: calc(100% - 100px);
  }

  input.has-prefix {
    display: inline-block;
    width: auto;
  }

  .btn-progress {
    height: 30px;
    width: 90px;
    background-color: $color-main;

    span {
      color: #FFF;
      position: absolute;
      top: 4px;
      left: 14px;
    }
  }

  button+.mtrl-select {
    width: calc(100% - 70px);
  }
}

.info-table {
  width: 100%;
  margin-bottom: 30px;

  td {
    padding: 2px 8px;
  }

  tr:nth-child(odd) {
    background: $color-light-green;
  }

  td:last-child {
    text-align: right;
    width: 30px;
  }

  &-icon {
    cursor: pointer;
  }

  &-icon .fa-close:hover {
    color: $color-red;
  }

  &-icon .fa-check:hover {
    color: $color-green;
  }
}

.users-table {
  width: 100%;
  margin-bottom: 30px;

  thead tr {
    background: $color-light-green;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody td {
    padding: 4px;
  }

  tbody tr:hover {
    background: #efe;
  }

  td:last-child {
    text-align: right;
    width: 30px;

    i {
      font-size: 20px;
    }
  }
}

.top-search ul.search-results {
  background-color: #FFF;
  opacity: 0.9;
  position: absolute;
  list-style: none;
  width: 100%;
  border-right: 5px;
  margin-top: 2px;
  padding: 4px;
  z-index: 200;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);

  li:nth-child(even) {
    margin: 4px 0;
  }

  li:hover {
    background-color: #e2f5f4;
  }

  li a {
    display: block;
  }

  li.divider:hover {
    background-color: #FFF;
  }
}

.results-avatar {
  width: 40px;
  height: 40px;
  background-size: cover;
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
}

.editing-info .edu-container {
  border: 1px solid $color-main;
  padding: 8px;
  margin-bottom: 20px;

  .info-table-icon {
    float: right;
    display: inline-block;
  }
}

.editing-info .pull-left {
  text-align: center;
  margin-bottom: 1rem;
}

.editing-info .pull-left.selectPageCont {
  border: 1px solid #5B7276;
  border-radius: 5px;
}

.editing-info .pull-left.selectPageCont label {
  background-color: #5B7276;
  color: white;
  padding: 2px 10px;
  margin: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.editing-info .advertising.selectPage {
  width: 100%;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 3px 0;
  color: #424242;
  font-weight: 500;
  transition: 0.3s;
}

.editing-info .advertising.selectPage:hover {
  cursor: pointer;
}

.user-list-icon {
  color: $color-dark-green;

  i {
    border: 2px solid transparent;
  }
}

.user-list-icon.icon-active i {
  border: 2px solid $color-green;
}

.memobjects {
  position: absolute;
  z-index: 1;
  height: 120px;
  width: 540px;
  top: 150px;
  right: 6px;

  @media screen and (max-width: 991px) {
    width: 160px;
  }

  .mo-add {
    position: absolute;
    top: -36px;
    right: 20px;
    color: $color-lime;
    background-color: $color-dark-green;
    border: 1px solid $color-lime;
    padding: 2px 8px;
    cursor: pointer;
    height: 26px;
    white-space: nowrap;


    @media screen and (max-width: 991px) {
      right: 0px;
    }

    i {
      margin-right: 10px;
    }
  }

  .mo-add:hover {
    color: $color-dark-green;
    background-color: $color-lime;
    border: 1px solid $color-dark-green;
  }

  .postedby {
    position: absolute;
    top: 75px;
    color: $color-lime;
    background-color: $color-text;
    padding: 4px 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow: hidden;
    height: 28px;
    text-overflow: clip;
    white-space: nowrap;
    font-size: 14px;
    visibility: hidden;
  }

  .premium {
    position: absolute;
    color: $color-lime;
    background-color: $color-text;
    padding: 4px 10px;
    left: 4px;
    top: 4px;
    visibility: hidden;
  }

  .postedby.modelete {
    top: 2px;
    right: -8px;
    width: 22px;
    height: 22px;
    left: unset;
    padding: 4px 6px;
  }

  .memobject {
    position: relative;
    width: 160px;
    height: 120px;
    border: 2px solid transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }

  .memobject-empty {
    color: $color-lime;
    border: 2px solid $color-lime;
    text-align: center;
    padding-top: 42px;
  }

  .memobject:hover {
    border: 2px solid $color-lime;

    .postedby,
    .premium {
      visibility: visible;
    }
  }
}

.attachments.ad {
  border-color: #ccc;
}

.attachments {
  border: 1px solid #eee;
  padding: 10px;
  text-align: right;
  border-top: 0;

  &>ul {
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
  }


  li {
    display: inline-block;
    margin-left: 5px;

    &>i {
      font-size: 30px;
    }
  }

  .selected-file {
    float: left;
    display: inline-block;
    margin-top: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80%;
    text-align: left;
    white-space: nowrap;
    position: relative;
    padding: 5px 40px 5px 10px;
    border: 1px solid $color-dark-green;
    border-radius: 5px;

    .remove-selected {
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
      margin-left: 20px;
    }

    .progress {
      background-color: $color-lime;
      height: 3px;
      position: absolute;
      bottom: 0;
    }
  }
}

form .progress {
  background-color: $color-lime;
  height: 3px;
}

.gallery-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  text-align: center;

  &-image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-pdf,
  &-pdf canvas {
    height: 100%;
  }
}

.post-meta {
  width: 100%;
  text-align: center;

  img,
  video,
  audio,
  canvas {
    margin-top: 10px;
    max-width: 100%;
    max-height: 500px;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    ;
  }

  .we-video-info {
    text-align: left;
  }
}

.pdf-container {
  position: relative;

  .nav {
    position: absolute;
    top: 50%;
    cursor: pointer;

    &.nav-prev {
      left: 10px;
    }

    &.nav-next {
      right: 10px;
    }
  }
}

.gallery-popup-pdf.pdf-container {
  .nav {
    color: $color-white;
    font-size: 40px;
  }
}

.popup-form-btns {
  text-align: right;
  margin-top: 20px;
}

.file-input-wrapper {
  width: 100%;
}

.form-file-upload {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $color-main;
  width: 100%;
  cursor: pointer;
  text-align: center;

  &-hidden {
    display: none;
  }
}

.grid-row-img {
  width: 50px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.mo-container {
  text-align: left;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;

  .mo-item-holder {
    display: inline-block;

    .mo-item-price {
      text-align: center;
    }

    .mo-item {
      width: 80px;
      height: 80px;
      margin: 10px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      max-height: 200px;
      overflow-y: auto;
      border: 2px solid transparent;

      &-selected {
        border: 2px solid $color-main !important;
      }

      &:hover {
        border: 2px solid $color-light-green;
      }

    }
  }

}

.photos-container {

  .photos-empty {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .photos-holder {
    width: 25%;
    display: inline-block;
    text-align: center;
    position: relative;

    @media screen and (max-width: 990px) {
      width: 50%;
    }

    .photos-item {
      width: 200px;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin: 10px;
      cursor: pointer;
      display: inline-block;
    }

    .owner-icon {
      color: $color-lime;
      float: right;
      margin: 5px;
    }

    .icon-delete {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      visibility: hidden;
    }

    .icon-memento {
      position: absolute;
      top: 28px;
      right: 2px;
      cursor: pointer;
      visibility: hidden;
    }
  }

  .photos-holder:hover {

    .icon-delete,
    .icon-memento {
      visibility: visible;
    }
  }
}

.photos-container.small.avatars .photos-holder {
  width: 20%;
  vertical-align: bottom;

  .photos-item {
    margin-bottom: 0;
  }

  @media screen and (max-width: 990px) {
    width: 25%;
  }

  @media screen and (max-width: 420px) {
    width: 50%;
  }

  .photo-title {
    height: 40px;
  }
}

.photos-container.small.avatars.sidebar-photos .photos-holder {
  width: 48%;

  .photos-item {
    width: 64px;
    height: 64px;
  }
}

.photos-container.small .photos-holder {
  width: 12%;

  @media screen and (max-width: 990px) {
    width: 25%;
  }

  .photos-item {
    width: 100px;
    height: 100px;
  }
}

.carousel-title {
  font-weight: bold;
  margin-top: 10px;
}

.slick-photo-slider {

  .slick-list {
    display: inline-block;
    width: calc(100% - 40px);
    vertical-align: middle;
  }

  .slick-arrow {
    display: inline-block;
    cursor: pointer;
  }

  .slick-arrow.slick-disabled {
    color: $color-grey;
    cursor: default;
  }

  .slick-arrow.slick-prev {
    margin-right: 8px;
  }

  .slick-arrow.slick-next {
    margin-left: 8px;
  }

  .slick-photo {
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .slick-text {
    height: 40px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.contct-info.policies {
  padding: 80px;
  text-align: justify;

  @media screen and (max-width: 992px) {
    padding: 20px;
  }

  h3 {
    margin-bottom: 60px;
  }

  a {
    color: $color-main;
  }

  &>ul {
    margin-top: 20px;
    padding-left: 12px;
    list-style-type: upper-roman;

    &>li {
      margin-top: 20px;
    }
  }
}

.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;

  .loading {
    top: 50%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}


.modal-body .form-radio .token-input {
  display: inline-block;
  opacity: 1;
  position: relative;
  margin-left: 20px;
  width: 324px;
}

.modal-body .form-radio>.radio {
  width: 100%;
  margin-top: 0;
}

.no-content {
  text-align: center;
  margin: 50px;
  font-size: 16px;
}

.tabpanel-holder {
  display: flex;
}

.f-title.tabpanel-header {
  flex: 1;
  cursor: pointer;
  padding-left: 20px;

  &:before {
    visibility: hidden;
  }

  &.tab-active:before {
    visibility: visible;
  }
}

.modal-content {
  .modal-footer {
    .info-box {
      font-size: 12px;

      ul {
        padding-left: 20px;
      }
    }

    &>div:first-child {
      margin-right: auto;
    }
  }
}

.topbar.stick .login-form {
  text-align: left;
  padding-left: 10px;

  input {
    width: 200px;
    max-width: 200px;
  }

  button {
    min-width: 150px;
    max-width: 250px;
  }
}

.topbar.stick .login-form .forgot-container {
  display: inline-block;
  position: relative;
  height: 40px;
  vertical-align: bottom;
  margin-left: 10px;

  .forgot-pwd {
    color: $color-lime !important;
  }

  .checkbox {
    margin: 0px;
    position: absolute;
    top: 18px;
    min-width: 180px;

    span {
      font-size: 12px;
      color: $color-lime;
    }
  }

}

.timeline-info .mtr-btn.btn-progress.header-join-btn {
  height: 36px;
  float: right;
  margin: 10px 0;
  line-height: normal;
  padding: 6px 12px;
}

.upload-msg {
  text-align: center;
  padding: 50px;
  font-size: 22px;
  color: $color-main;
  width: 260px;
  max-width: 100%;
  margin: 50px auto;
  border: 1px solid $color-main;
}

.topbar.stick .container .fa-home {
  color: $color-lime;
  font-size: 28px;
  margin: 15px;
}

.feed-left {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: transparent;
  overflow-x: hidden;
  padding: 80px 10px 120px 10px;
}

.editing-info {
  .submit-btns {
    width: unset;
    float: right;
    margin-top: -10px;
    margin-bottom: 0;
  }
}

.multi-select-input {
  width: 100%;
}

.custom-tooltip {
  position: fixed;
  z-index: 1000;
  background-color: $color-white;
  border: 1px solid $color-text;
  padding: 20px;
  max-width: 500px;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.feed-right {
  height: 100%;
  width: 265px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: transparent;
  overflow: hidden;
  padding: 70px 10px 20px 10px;
}

.feed-right .feedBackButton {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: #04A5C0;
  border: 1px solid #04A5C0;
  font-size: 15px;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 10px;
  transition: 0.3s;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.feed-right .feedBackButton i {
  vertical-align: middle;
  margin-right: 0.2rem;
}

.feed-right .feedBackButton:hover {
  color: #424242;
  background-color: transparent;
  cursor: pointer;
}

.feed-right .sidebarTitle {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #424242;
  border-bottom: 2px solid #04A5C0;
  font-size: 15px;
  margin-bottom: 20px;
}

.feed-right .sidebar.right,
.modal-body .sidebar.right {
  width: 246px;
  text-align: left;
  position: relative;
  height: 150px;
  padding-bottom: 0;
  // background-color: #F0FAFA;
  background-color: white;
  margin-top: 10px;
  display: flex;
  margin-bottom: 40px;
  border-radius: 10px;
  border: 1px solid #04A5C0;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

  .sbr-image {
    height: 80px;
    width: 100px;
    border-radius: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .sbr-image-notext {
    width: 100%;
    height: 100%;
  }

  canvas {
    max-width: 100%;
    max-height: 80px;
  }

  .sbr-text {
    max-height: 180px;
    display: inline-block;
    background: transparent;
    color: $color-text;
    padding: 4px 8px;
    overflow: auto;
    overflow-x: hidden;
    vertical-align: top;
    flex: 1;
    margin-top: 15px;
  }
}

.reactour__popover {
  padding: 30px 36px !important;
}

.bubbleWrapper {
  z-index: 1000;
}

.form-gallery {
  margin-bottom: 20px;

  .submit-btns {
    margin-top: 30px;
    margin-bottom: 0;
  }
}

.stripe-card-container {
  border: 1px solid $color-text;
  height: 28px;
  border-radius: 3px;
  padding: 5px;
}

.fbshare {
  float: right;
}

.post-tag-name {
  border: 1px solid $color-text;
  border-radius: 3px;
  padding: 1px;
  margin: 3px;
  display: inline-block;

  &:hover .fa-times {
    display: inline;
  }

  .fa-times {
    cursor: pointer;
    display: none;

    &:hover {
      color: $color-red;
    }
  }
}

.post-tag-dropdown {
  width: 200px;

  .multi-select-input.searchable span:first-child {
    display: none
  }

  .react-select__menu {
    z-index: 5000;
  }
}

.new-postbox .new-post-link {
  margin-top: 10px;
  display: flex;

  input {
    flex: 1;
    margin-left: 10px;
    border: 1px solid $color-grey;
  }
}

.ql-toolbar {
  border-color: #eee;
}

.ql-container {
  border-color: #eee;
  height: 150px !important;
  max-height: 150px;
  overflow: hidden;
}

.form-group.image-preview {

  .sbr-image,
  canvas {
    height: 80px;
    max-width: 100px;
  }

  .sbr-image {
    background-size: cover;
  }
}

.sidebar.right {
  .report-ad-btn {
    position: absolute;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    // visibility: hidden;
  }

  &:hover .report-ad-btn {
    visibility: visible;
  }
}

.popup-reported-ads-table {
  width: 100%;
  text-align: left;
}

.centering {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.transparent_background {
  .modal-content {
    background: transparent;
    border: 0px;
  }
}

.datefield-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt-10 {
  margin-top: 10px;
}