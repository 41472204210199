:root {
    --containerBG: #E9E9E9;
    --mainBG: #ffffff;
    --mainText: #49575A;
    --subText: #B8B8B8;
    --mainDarkBG: #323336;
}

.boardContainer {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--containerBG);
}

.boardContainer .board {
    display: inline-block;
    border-radius: 10px;
    background-color: var(--mainBG);
    min-width: 200px;
    margin: 10px 10px 10px 0;
    vertical-align: top;
    min-height: 91px;
}

.boardContainer .board.large {
    display: block;
}

.boardContainer .board .title {
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--mainText);
    font-size: 16px;
    font-weight: 600;
}

.boardContainer .board .title div {
    float: right;
}

.boardContainer .board .title div i{
    font-size: 19px;
    margin-top: 3px;
    transition: 0.3s;
}

.boardContainer .board .title div:hover > i {
    cursor: pointer;
    /* transform: rotate(180deg); */
}

.boardContainer .board .body {
    padding: 5px 10px;
}

.boardContainer .board .chart {
    width: 350px;
    height: 250px;
    transition: 0.3s;
}

.boardContainer .board .chart svg {
    stroke: var(--subText);
    font-weight: lighter;
}

.boardContainer .board .imageScroll {
    /* width: 320px; */
    height: 0px;
    /* height: 250px; */
    transition: 0.3s;
    text-align: center;
    overflow: scroll;
}

.boardContainer .board .imageScroll .dataElement {
    display: inline-block;
    margin: 5px 10px;
}

.boardContainer .board .imageScroll .dataElement .imgContainer {
    position: relative;
}

.boardContainer .board .imageScroll .imgCover {
    background-color: black;
    width: 200px;
    border-radius: 10px;
    z-index: 1;
    height: 200px;
    margin: 0px auto 10px auto;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 0px;
    bottom: 0px;
    opacity: 0;
    transition: 0.3s;
}

.boardContainer .board .imageScroll .imgCover p {
    color: white;
    background-color: white;
    color: black;
    width: 130px;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 16px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.boardContainer .board .imageScroll .imgCover:hover {
    opacity: 0.8;
    cursor: pointer;
}

.boardContainer .board .imageScroll img {
    width: 200px;
    display: block;
    margin: 10px auto 10px auto;
    border-radius: 10px;
}

.boardContainer .board .imageScroll svg {
    stroke: var(--subText);
    font-weight: lighter;
}

.boardContainer .board .imageScroll sub {
    background-color: var(--mainDarkBG);
    color: white;
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 10px;
}

.boardContainer .board .hiddenText {
    margin: 0;
    font-weight: 500;
}

.boardContainer .board .body .data p {
    margin: 0;
    font-size: 26px;
    color: var(--mainText);
}

.boardContainer .board .body .data sub {
    color: #B8B8B8;
    font-size: 12px;
    bottom: 0;
    left: 0;
}

.boardContainer .locatedUsers {
    background-color: var(--mainDarkBG);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: 0.3ms;
}

.boardContainer .locatedUsers p {
    color: white;
    margin: 0;
    padding: 5px 10px;
    transition: 0.3ms;
    font-size: 16px;
}

@media only screen and (max-width: 690px) {
    .boardContainer .board {
        display: block;
    }
}