.logsCnt {
    background-color: #323336;
}

.logsCnt h2 {
    color: white;
}

.logsCnt .headerCnt {
    text-align: center;
    padding-top: 5rem;
}

.logsCnt .headerCnt .head {
    background-color: white;
    width: 30rem;
    margin: 0 auto;
    border-radius: 5px;
    padding: 1rem 0.5rem;
}

.logsCnt .headerCnt .head p {
    margin-bottom: 0;
    font-size: 16px;
}

.logsCnt .headerCnt .head input {
    border-bottom: 1px solid #323336;
}

.logsCnt a {
    margin: 1rem;
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #323336;
    font-size: 16px;
    transition: 0.3s;
}

.logsCnt a:hover {
    background-color: #2096F3;
    color: white;
}

.logsCnt .vertical-timeline-element-date {
    color: white;
}

.logsCnt .tag {
    display: inline-block;
    background-color: #323336;
    padding: 3px 10px;
    border-radius: 5px;
}

.logsCnt .tag p {
    margin: 0;
    color: white;
}