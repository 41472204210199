@media screen and (min-width: 992px) { 
  .feed-left-container {
    display: none;
  }
  .sidebar.static .widget {
    background-color: transparent;
/*    color: #222;
    border: 1px solid $color-text;*/
  }

  .feature-photo .container-fluid {
    padding-right: 0;
    .col-lg-2 {
      min-width: 158px;
    }
    .col-lg-10 {
      max-width: calc(100% - 160px)
    }    
  }

  .only-mobile {
    display: none;
  } 
}

@media screen and (max-width: 992px) { 
  .feed-left, .feed-right {
    display: none;
  }
  .theme-layout.marble > .container {
    max-width: 992px !important;
  }
  .row.page-contents {
    padding-top: 0;
  }
}

@media screen and (max-width: 7680px) { 
  .theme-layout.marble > .container:not(.welcome) {
    max-width: calc(100% - 250px);
  }
}

@media screen and (max-width: 980px) {
  .sidebar.static {
    .widget.stick-widget {
      padding-top: 0;
      .widget-title {
        margin-bottom: 2px;
        padding: 8px 20px;
      }
      li {
        margin-bottom: 2px;
      }
      &>ul {
        padding-bottom: 8px;
      }
    }
  }

  footer#site-footer {
    padding: 0 20px 10px;
    .col-lg-2:last-child .widget{
      margin-top: 8px;
    }
    .widget-title {
      margin-bottom: 0;
    }
    .col-lg-8 {
      display: none;
    }
  }
}

@media screen and (max-width: 640px) {
  .feature-photo .user-avatar {
    width: 100px;
    height: 100px;

    .edit-phto {
      height: 30px;
    }

    figure .user-avatar-image {
      width: 100px;
      height: 100px;
    }
  }

  .feature-photo .memobjects {
    width: 200px;
    height: 75px;
    top: 196px;

    .memobject {
      width: 100px;
      height: 75px;
    }
  }
}

@media screen and (max-width: 360px) {
  .users-table.honorees td:first-child {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .sidebar.static .widget.stick-widget ul li {
      width: 100%;
  }
}